
/* @import '~antd/dist/antd.css'; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","Open Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
}

#root{
  display: flex;
  width:100%;
  height:100%;
  /* min-height:900px; */
}
input{
  outline: none;
}
p{
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
}
.visible{
  visibility:visible !important;
}
@font-face {
  font-family: "Roboto";
  font-weight: bold;
  font-style: normal;
  src: url("./fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: '300';
  font-style: normal;
  src: url("./fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 300;
  font-style: normal;
  src: url("./fonts/OpenSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  font-style: normal;
  src: url("./fonts/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  font-style: normal;
  src: url("./fonts/OpenSans-Bold.ttf") format("truetype");
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
